<template>
  <div class="dashboard__col literature">
    <!-- <h1>{{ getTitle }}</h1> -->
    <!-- <div class="orders__col">
      <div class="container">
        <div class="card-liter" v-for="(item, ind) in literature" :key="ind">
          <div class="header" @click="$router.push({name: 'usefulInfo', params: { id: item.id, lang: langStorage}})">
            <img class="card-img" :src="item.image" alt="">
          </div>
          <div class="title" @click="$router.push({name: 'usefulInfo', params: { id: item.id, lang: langStorage}})">
            <h4>{{ item.title }}</h4>
          </div>
          <p class="desc inner-p" v-html="item.description"></p>
        </div>
      </div>
    </div> -->

    <div class="orders__col">
      <div class="requests__table">
        <div class="t__top">
          <!-- <div class="name">{{ getTitle }}</div> -->
        </div>
        <!-- <img src="/img/spiner.gif" alt="" style="width: 100px" v-show="!protocols.length"> -->
          <div class="rq__card" v-for="(item, ind) in literature" :key="ind">
            <div class="header" v-if="item.description.length" @click="$router.push({name: 'usefulInfo', params: { id: item.id, lang: langStorage}})">{{item.title}}</div>
            <div class="name" v-else @click="openWindow(item)">{{item.title}}</div>
            <!-- <div class="name" v-else>{{item.description}}</div> -->
          </div>
      <!-- <h3 style="width:100%; margin-top: 50px; text-align:center;" v-if="!protocols.length">{{$t('protocols.message')}}</h3> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

const domain = process.env.VUE_APP_BASE_API;

export default {
  data(){
    return {
      literature: []
    }
  },
  async beforeMount(){
    this.literature = await this.getLiterature();
  },
  watch:{
    async langStorage(){
      this.literature = await this.getLiterature();
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
      user: 'user/user'
    }),
    getTitle(){  
      return this.langStorage == 'ro' ? 'Informații utile' : this.langStorage == 'ru' ? 'Полезная информация' : 'Useful info';
    }
  },
  methods: {
    openWindow(item){
      item.literature_files.forEach(el =>{
        let url = el[`file_${this.langStorage}`]
        window.open(url, '_blank')
      })
    },
    async getLiterature(){
      try {
        const result = await fetch(`${domain}/${this.langStorage}/literature/?page_size=10000`, {
          headers: {
            Authorization: `Token ${this.user.token}`
          }
        });
        if(result.ok){
          const data = await result.json();
          return data.results;
        }
        else {
          console.error(result);
          return [];
        }
      }
      catch(err){
        console.error(err);
      }
    }
  }
}
</script>

<style lang="scss">
.night { 
  .dashboard__col.literature {
    color: white;
  }
}
.literature {
  margin-top: 2em;
  .container {
    display: flex;
    flex-wrap: wrap;
  }
  .rq__card{
    cursor: pointer;
  }
  .card-liter {
    width: 100%;
    max-width: 300px;
    margin: 10px;
    border-radius: 10px;
    transition: 0.3s;
    &:hover {
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    }
    .header {
      cursor: pointer;
      .card-img {
        width: 100%;
        height: 320px;
        border-radius: 10px;
        object-fit: cover;
      }
    }
    .title {
      padding-left: 15px;
      cursor: pointer;
      h4 {
        height: 18px;
        overflow-y: hidden;
      }
    }
    .desc {
      padding-left: 15px;
      height: 73px;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* количество строк */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media(max-width: 430px){
    .card-liter {
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    }
  }

}
@media(max-width:1200px) and (min-width: 430px) {
  .dashboard__col .orders__col .order .order__num {
    margin-right: 100px !important;
  }
}
</style>